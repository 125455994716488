.quote {
    padding: 84px 0 0 0;
    margin: 0;
    background: {
        image: url('/assets/quote-black.png');
        size: 112px auto;
        repeat: no-repeat;
        position: 0 0;
    }

    .quoteText {
        font-size: 1.6em;
        line-height: 1.25;
        margin: 0 0 0.5em 0;
        font-family: var(--cf-font-telegraf-med);
    }

    .quoteAuthor {
        font-weight: 700;
        margin-bottom: 0.25em;
    }

    .quoteRole {
        font-size: 0.92em;
    }
}

@media (max-width: 768px) {
    .quote {
        padding: 64px 0 2em 0;
        margin: 1em 0;
        background-size: 60px auto;
        background-position: 0 12px;

        .quoteText {
            font-size: 1.125em;
        }
    }
}

@media (min-width: 1024px) {
    .quote {
        padding: 0 0 0 144px;
        background-position: 0 0.25em;
    }
}